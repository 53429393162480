<template>
  <div id="pay" :style="`padding-bottom:${Height}px`">
    <!-- <div id="pay"> -->
    <el-steps :active="index" finish-status="success" :align-center="true">
      <el-step title="选择支付方式"></el-step>
      <el-step title="收银台"></el-step>
      <el-step title="支付完成"></el-step>
    </el-steps>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "pay",
  data() {
    return {
      index: 0,
      Height: "",
      orderNum: "",
      // orderInformation: "",
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (
          val.name != "none-order" &&
          sessionStorage.getItem("orderNum") != val.query.orderNum
        ) {
          this.$router.push({
            path: `/Pay/NoneOrder`,
          });
        }

        val.name === "mode-of-payment" && (this.index = 0);
        val.name === "cashier" && (this.index = 1);
        val.name === "completion-of-payment" && (this.index = 3);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    // console.log(window.innerHeight);
    // console.log(document.body.clientHeight);
    // this.Height = window.innerHeight - document.body.clientHeight;
    // console.log(this.Height);
    // Cookies.get("oder")
    //   ? ""
    //   : this.$router.push({
    //       name: `shopping-cart`,
    //     });
    // this.orderInformation = JSON.parse(Cookies.get("oder"));
    // this.orderInformation.totalAmount = decimals(
    //   this.orderInformation.totalAmount
    // );
    // console.log(this.orderInformation );
  },
  mounted() {
    window.innerHeight - document.body.clientHeight >= 0
      ? (this.Height = window.innerHeight - document.body.clientHeight + 60)
      : (this.Height = "");
  },
  beforeDestroy() {
    sessionStorage.removeItem("orderNum");
    sessionStorage.removeItem("totalAmount");
    sessionStorage.removeItem("Time");
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#pay {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border-top: 1px solid var(--subjectColor);
  padding-bottom: 60px;
  ::v-deep .el-steps {
    width: 520px;
    padding: 30px 0;
    .el-step__head {
      .el-step__icon {
        width: 26px;
        height: 26px;
        .el-step__icon-inner {
          font-weight: 400;
        }
        i::before {
          color: #00C45C;
          font-weight: 700;
        }
      }
    }
    .el-step__main {
      .is-success {
        color: #00C45C;
      }
      .el-step__title {
        font-size: 14px;
      }
    }
    .is-success {
      border-color: #00C45C;
    }
    .is-process {
      color: #00C45C;
      border-color: #00C45C;
      .is-text {
        background: #00C45C;
        color: #fff;
      }
    }
  }
}
</style>